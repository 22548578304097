import { useUser } from '../composables/apis/user'
import { ROUTES_NAMES } from '../routes'
import '../static/script/intercom.js'
export default defineNuxtRouteMiddleware((to) => {
  if (useUser().isLoggedIn.value && !to.fullPath.includes('/auth/business')) {
    return navigateTo({
      name: ROUTES_NAMES.pickAccount,
    })
  }
  if (window.Intercom) {
    window.Intercom('boot', {
      api_base: 'https://api-iam.intercom.io',
      app_id: 'xd9igd3d',
    })
    window.Intercom('update')
  }
})
